import React from 'react'
import { Link } from 'gatsby'
import routes from '../config/routes'
import config from '../config'

const FAQs2019 = [
  {
    sectionTitle: 'About Active Kids',
    children: [
      {
        title: 'What is Active Kids?',
        content: (
          <div>
            <p>
              Sainsbury’s Active Kids holiday clubs are designed to help kids to
              be more active and lead healthier lives in the summer holidays, at
              a great price for parents.
            </p>
            <p>
              We want our holiday clubs to be fun for everyone, so our Active
              Kids will be trying all sorts of activities including sports such
              as tennis, gymnastics and dodgeball, alongside arts & crafts,
              circus skills and the opportunity to try a para-sport! Kids will
              be grouped by age, helping to build their confidence with new
              people and new activities. All of this is only £7.50 per child per
              day, and includes a healthy lunch and snacks.
            </p>
            <p>
              Our clubs run in the summer holidays, from 9am-4pm and are
              delivered by Premier Education trained and accredited coaches.
            </p>
          </div>
        ),
      },
      {
        title: 'What happened to the voucher scheme?',
        content: (
          <div>
            <p>
              The Active Kids voucher scheme was around for thirteen years, and
              together we’re proud to have donated over £186m of equipment to
              schools and clubs around the UK.
            </p>
            <p>
              Last year we took a fresh look at how we could help keep kids
              active and launched our Sainsbury’s Active Kids holiday clubs,
              replacing our voucher scheme. We want to concentrate on the areas
              where we can make the biggest difference, and with parents telling
              us it’s hard to keep children active during the holidays, we
              believe our Active Kids Holiday Clubs can do more to help.
            </p>
            <p>
              Thousands of children took part last year, enjoying a wide range
              of activities from fencing and circus skills to tennis and quick
              cricket, so we’ve expanded this year to 70 locations and are just
              as committed as ever to keeping kids healthy and active.
            </p>
          </div>
        ),
      },
      {
        title:
          'I have some Active Kids vouchers, can I still hand them into my local school or organisation?',
        content: (
          <div>
            <p>
              No, the new Active Kids holiday clubs scheme won’t involve
              exchanging vouchers for equipment and experiences anymore. Schools
              and organisations will no longer be accepting vouchers.
            </p>
          </div>
        ),
      },
      {
        title: 'What about Active Kids for All?',
        content: (
          <div>
            <p>
              Our Active Kids for All Inclusive PE Training programme will
              continue. We’re committed to supporting inclusive and accessible
              activity for all children and will carry on working with the
              Activity Alliance to deliver this training to teachers. To find
              out more about the Inclusive PE Training and find a workshop near
              you please visit
              <a href="http://www.inclusivepe.org.uk">
                {' '}
                www.inclusivepe.org.uk
              </a>
            </p>
          </div>
        ),
      },
      {
        title: 'Are you still working with the Active Kids ambassadors?',
        content: (
          <div>
            <p>
              We’re thrilled that gold medallist and Strictly Come Dancing star,
              Jonnie Peacock and Paralympic swimming legend Ellie Simmonds will
              be continue to be our Active Kids ambassadors for 2019.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionTitle: 'Sainsbury’s Active Kids holiday clubs',
    children: [
      {
        title: 'Do children have be pupils at the school to attend?',
        content: (
          <div>
            <p>
              No. Any children aged 5 – 15 years can attend Sainsbury’s Active
              Kids holiday clubs, they don’t have to be pupils at the school but
              there are limited spaces.
            </p>
          </div>
        ),
      },
      {
        title: 'Are the clubs supervised?',
        content: (
          <div>
            <p>
              Yes. The clubs will be run by our partners Premier Education who
              are very experienced at running holiday clubs. All staff are
              trained, accredited and DBS checked.
            </p>
          </div>
        ),
      },
      {
        title: 'Who are Premier Education?',
        content: (
          <div>
            <p>
              Premier Education are an experienced holiday club provider in the
              UK. All their staff are fully trained, accredited and DBS
              checked..
            </p>
            <p>
              Sainsbury’s partnered with Premier Education to deliver our Active
              Kids holiday clubs trial in 2018, and will continue to work with
              Premier Education this summer. To find out more about Premier
              Education, click
              <> </>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.premier-education.com/"
              >
                here
              </a>
              .
            </p>
          </div>
        ),
      },
      {
        title: 'What should my child bring with them to the club?',
        content: (
          <div>
            <p>
              Your child should wear clothing that they can take part in sports
              and physical activities in.
            </p>
            <ul>
              <li>Trainers or sensible footwear</li>
              <li>Comfortable clothing</li>
              <li>A coat in case it rains</li>
              <li>Sun cream on hot days</li>
              <li>A reusable water bottle (if you have one)</li>
              <li>Any medication your child might need</li>
              <li>
                Please also bring your booking confirmation when you drop your
                child off
              </li>
              <li>
                <>
                  A packed lunch if your child has dietary requirements that we
                  cannot cater for, if you are unsure please call our Premier
                  careline on
                </>
                <> </>
                <a href="tel:01953 499088">01953 499088</a>
              </li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Do the clubs cater for special needs?',
        content: (
          <div>
            <p>
              <>
                We are committed to ensuring our Active Kids holiday clubs are
                as inclusive and accessible as possible. If your child does have
                a medical condition, allergy or disability please make us aware
                of this during the registration process. We may then give you a
                call upon registration to ensure we better understand and can
                facilitate your child if they attend. Our Activity Providers are
                fully trained in health & safety as well as disability inclusion
                to adapt activities as best as possible if need be. However, we
                unfortunately cannot provide one-to-one care at this time.
                Please give us a call on
              </>
              <> </>
              <a href="tel:01953 499 088">01953 499 088</a>
              <> </>
              <>if you’d like to discuss your child’s needs specifically.</>
            </p>
            <p>
              We work hard to make sure our clubs are fun, safe and as inclusive
              as possible. Therefore if there are any behavioural issues which
              put children or coaches at our clubs at risk, we reserve the right
              to call parents/guardians and ask for them to pick up their
              child/children.
            </p>
          </div>
        ),
      },
      {
        title: 'Do you provide lunch at an Active Kids holiday club?',
        content: (
          <div>
            <p>
              Yes we do. We will provide a healthy packed lunch for all children
              attending the holiday clubs. We will also provide plenty fruit and
              snacks throughout the day.
            </p>
            <p>
              Please note that we will make dietary accommodations where
              possible, however if your children has any allergies or would
              prefer to bring their own lunch, please provide them with a packed
              lunch.
            </p>
            <p>
              It would be helpful if you could provide your child with a
              reusable water bottle if you have one.
            </p>
          </div>
        ),
      },
      {
        title: 'Why aren’t any clubs in my local area?',
        content: (
          <div>
            <p>
              We have expanded to 70 locations this year, it’s easy to search
              for clubs by area or postcode to see if there’s one near you.
            </p>
          </div>
        ),
      },
      {
        title: 'What time does the holiday club start and end?',
        content: (
          <div>
            <p>
              Registration opens at 8:45am and the club ends at 4pm.
              Unfortunately, we can’t look after children outside these times.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionTitle: 'My Booking',
    children: [
      {
        title: 'How do I book my children on to the clubs?',
        content: (
          <p>
            <>By clicking on this </>
            <Link to={routes.venues}> link</Link>
            <>
              . Booking is simple, just choose a club near you, enter some
              details about yourself and your child, and then pay by card or
              Nectar points.
            </>
          </p>
        ),
      },
      {
        title: 'Is there a maximum number of days I can book for each child?',
        content: (
          <p>
            You can book a child onto as many days as you want. We encourage
            children to take part for a full week to ensure the experience all
            the great activities on offer.
          </p>
        ),
      },
      {
        title: 'How many children can I book places for?',
        content: (
          <p>
            You can book a place for up to five children and they can attend as
            many days as they want. If you want to book more than 5 children you
            will need to make a separate booking.
          </p>
        ),
      },
      {
        title: 'Can I book a place on the clubs in a Sainsbury’s store?',
        content: (
          <p>
            <>No. A booking can only be made online. </>
            <Link to={routes.venues}>Click here to make your booking.</Link>
          </p>
        ),
      },
      {
        title: 'How do I amend or cancel my booking?',
        content: (
          <div>
            <p>
              You can view your booking
              <a href="https://activekids.premier-education.com">here</a>
              <>
                . If you would like to cancel or amend your booking please call
                Premier Education on
                <a href="tel:01953499088"> 01953 499088 </a>
                <>, Mon-Fri, 9am - 5pm</>
              </>
            </p>
            <p>We will accept cancellations on the following basis:</p>
            <ol type="a">
              <li>
                You have the right to cancel and receive a refund up to 15 days
                after the day we receive and confirm your booking; or
              </li>
              <li>
                If you book less than 15 days before your club is due to take
                place you may cancel up to the day before the club. You accept
                and agree that the right to cancel is lost once the contract is
                fully performed.
              </li>
              <p>
                <>
                  Our team at Premier will try to accommodate changes and
                  cancellations as best as possible, however we cannot guarantee
                  anything if outside of the timelines above. However, please do
                  give us a call and we will try to help as best we can. Please
                  call Premier Education
                </>
                <> </>
                <a href="tel:01953499088">01953 499088</a>
                <> or email them at </>
                <a href="mailto:activekids@premier-education.com">
                  activekids@premier-education.com
                </a>
              </p>
            </ol>
          </div>
        ),
      },
      {
        title:
          'How will Sainsbury’s and Premier Education use my personal contact details?',
        content: (
          <div>
            <p>
              Please see
              <a href="https://help.sainsburys.co.uk/help/website/privacy-policy-commitment">
                our privacy policy
              </a>
            </p>
          </div>
        ),
      },
      {
        title: 'How do I contact Premier Education?',
        content: (
          <div>
            <p>
              If you are still having difficulties please contact Premier
              Education’s friendly customer solutions team on
              <a href="tel:01953499088"> 01953 499088 </a>
              <>, Mon-Fri, 9am - 5pm.</>
            </p>
          </div>
        ),
      },
      {
        title:
          'Can I use childcare vouchers or tax free credits to pay for a place?',
        content: (
          <div>
            <p>
              No. Unfortunately we are unable to accept childcare vouchers or
              tax free vouchers as payment towards our Active Kids clubs.
              However, you can pay for places at a club using your Nectar points
              if you have enough saved up.
            </p>
          </div>
        ),
      },
    ],
  },
]

const FAQs2020 = [
  {
    sectionTitle: 'About Active Kids',
    children: [
      {
        title: 'What is Active Kids?',
        content: (
          <div>
            <p>
              Sainsbury’s Active Kids holiday clubs are designed to help kids be
              more active and lead healthier lives in the summer holidays, at a
              great price for parents.
            </p>
            <p>
              We want our holiday clubs to be fun and inclusive for children of
              all abilities, so our Active Kids will be trying all sorts of
              activities including sports such as tennis, gymnastics and
              dodgeball, alongside arts and crafts and even circus skills! Our
              activity professionals are trained in inclusion and wherever
              possible support activities for children of all abilities. Kids
              will be grouped by age, helping to build their confidence with new
              people and new activities. All of this is only £10 per child per
              day, and includes a healthy lunch and snacks.
            </p>
            <p>
              Our clubs run in the summer holidays, from 9am-4pm and are
              delivered by Premier Education trained and accredited coaches. You
              will contract with Premier Education Group PLC.
            </p>
          </div>
        ),
      },
      {
        title: 'What happened to the voucher scheme?',
        content: (
          <div>
            <p>
              The Active Kids voucher scheme was around for thirteen years, and
              together we’re proud to have donated over £186m of equipment to
              schools and clubs around the UK.
            </p>
            <p>
              In 2018, we took a fresh look at how we could help keep kids
              active and launched our Sainsbury’s Active Kids holiday clubs,
              replacing our voucher scheme. We want to concentrate on the areas
              where we can make the biggest difference, and with parents telling
              us it’s hard to keep children active during the holidays, we
              believe our Active Kids Holiday Clubs can do more to help.
            </p>
            <p>
              Over the past 2 years thousands of children have taken part,
              enjoying a wide range of activities from arts and crafts, fencing
              and circus skills to tennis and quick cricket. We’ve expanded this
              year to 150 locations and are just as committed as ever to keeping
              kids healthy and active.
            </p>
          </div>
        ),
      },
      {
        title:
          'I have some Active Kids vouchers, can I still hand them into my local school or organisation?',
        content: (
          <div>
            <p>
              No, the new Active Kids holiday clubs scheme won’t involve
              exchanging vouchers for equipment and experiences anymore. Schools
              and organisations will no longer be accepting vouchers.
            </p>
          </div>
        ),
      },
      {
        title: 'What about Active Kids for All?',
        content: (
          <div>
            <p>
              <>
                Our Active Kids for All Inclusive PE Training programme will
                continue. We’re committed to supporting inclusive and accessible
                activity for all children and will carry on working with
                Activity Alliance to deliver this training to teachers. To find
                out more about the Inclusive PE Training and find a workshop
                near you, please visit
              </>
              <> </>
              <a href="http://www.inclusivepe.org.uk">www.inclusivepe.org.uk</a>
              <> or call Activity Alliance on 01509 227750.</>
            </p>
          </div>
        ),
      },
      {
        title: 'Are you still working with the Active Kids ambassadors?',
        content: (
          <div>
            <p>
              We are thrilled that gold medallist and Strictly Come Dancing
              star, Jonnie Peacock and Paralympic swimming legend Ellie Simmonds
              will continue to be our Active Kids ambassadors for 2020.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionTitle: 'Sainsbury’s Active Kids holiday clubs',
    children: [
      {
        title: 'Do children have to be pupils at the school to attend?',
        content: (
          <div>
            <p>
              No, they do not have to be a pupil at the school where the club is
              taking place - Children aged 5 – 15 years can attend Sainsbury’s
              Active Kids holiday clubs. All holiday club sessions are subject
              to availability.
            </p>
          </div>
        ),
      },
      {
        title: 'Are the clubs supervised?',
        content: (
          <div>
            <p>
              <>Yes. The clubs will be run by</>
              <> </>
              <a href="https://www.premier-education.com/">Premier Education</a>
              <> </>
              <>
                who are very experienced at running holiday clubs. All activity
                professionals are trained in inclusion and support activities,
                wherever possible, for children of all abilities. They are,
                accredited and DBS checked.
              </>
            </p>
          </div>
        ),
      },
      {
        title: 'Who are Premier Education?',
        content: (
          <div>
            <p>
              Premier Education are an experienced holiday club provider in the
              UK. All activity professionals are trained in inclusion and
              support activities, wherever possible, for children of all
              abilities. They are accredited and DBS checked.
            </p>
            <p>
              Sainsbury’s partnered with Premier Education to deliver our Active
              Kids holiday clubs trial in 2018, and we have continued to work
              together since. To find out more about Premier Education, click
              <> </>
              <a href="https://www.premier-education.com/">here</a>
              <>.</>
            </p>
          </div>
        ),
      },
      {
        title: 'What should my child bring with them to the club?',
        content: (
          <div>
            <p>
              Your child should wear comfortable clothing suitable for taking
              part in sports and physical activities. Please bring the following
              items and ensure the bag is clearly named:
            </p>
            <ul>
              <li>
                Your booking confirmation, which you will have been sent in an
                email shortly after booking. You’ll need to hand this in at
                registration
              </li>
              <li>Trainers or sensible footwear</li>
              <li>Comfortable clothing</li>
              <li>
                We recommend packing old clothing (for example, a large old
                shirt), in case your child takes part in Arts & Crafts
              </li>
              <li>A coat in case it rains</li>
              <li>Sun cream on hot days</li>
              <li>A reusable water bottle (if you have one)</li>
              <li>Hand sanitiser</li>
            </ul>
            <p>
              Please hand medication your child might need to a member of staff
              when you drop your child off. We reserve the right to refuse entry
              should your child be suffering from a contagious illness.
            </p>
            <p>
              Please feel free to provide a packed lunch if your child has
              dietary requirements that we cannot cater for. Healthy lunch,
              snacks and water will be provided throughout the day.
            </p>
            <p>Sample lunch menu:</p>
            <ul>
              <li>A sandwich (meat and vegetarian options)</li>
              <li>Carrot sticks</li>
              <li>A malt loaf bar</li>
              <li>A sugar free jelly pot</li>
            </ul>
            <p>Sample snack options:</p>
            <ul>
              <li>Fruit</li>
              <li>Cheese</li>
              <li>Yoghurt</li>
            </ul>
            <p>
              <>
                If you are unsure or need support on any of the above, please
                call our Premier Careline on
              </>
              <> </>
              <a href="tel:01953 499088">01953 499088 </a>
              <> </>
              or email
              <> </>
              <a href="mailto:activekids@premier-education.com">
                activekids@premier-education.com
              </a>
            </p>
          </div>
        ),
      },
      {
        title:
          'Do the clubs cater for special educational needs, disabilities and long term health conditions?',
        content: (
          <div>
            <p>
              We are committed to ensuring our Active Kids holiday clubs are as
              inclusive and accessible as possible. If your child does have a
              special educational need, medical condition, allergy or disability
              you must make us aware of this and provide as much information as
              possible during the registration process. We may then follow up
              with you to ensure we fully understand your child’s needs and can
              plan accordingly. Our Activity Providers are fully trained in
              health and safety as well as disability inclusion and therefore
              are confident adapting and tailoring activities to individual
              needs wherever possible.
            </p>
            <p>
              <>
                However, we unfortunately cannot provide one-to-one assistance
                at this time. Please give us a
              </>
              <> </>
              <a href="tel:01953 499088">01953 499088 </a>
              <> </>
              or email
              <> </>
              <a href="mailto:activekids@premier-education.com">
                activekids@premier-education.com
              </a>
              <> </>
              <>
                if you’d like to discuss your child’s specific needs before
                booking.
              </>
            </p>
          </div>
        ),
      },
      {
        title: 'What if my child is unhappy or misbehaves on the day?',
        content: (
          <div>
            <p>
              We are committed to ensuring our Active Kids holiday clubs are as
              inclusive and accessible as possible. If your child is unhappy on
              the day and/or their conduct puts other children or the
              instructors at risk, we reserve the right to require
              parents/guardians to collect their children from a session at
              immediate notice.
            </p>
            <p>
              Our Activity Providers are fully trained in health and safety as
              well as disability inclusion and therefore are confident at
              adapting and tailoring activities to individual needs wherever
              possible.
            </p>
            <p>
              <>
                However, we unfortunately cannot provide one-to-one assistance
                at this time. Please give us a
              </>
              <> </>
              <a href="tel:01953 499088">01953 499088 </a>
              <> </>
              or email
              <> </>
              <a href="mailto:activekids@premier-education.com">
                activekids@premier-education.com
              </a>
              <> </>
              <>
                if you’d like to discuss your child’s specific needs before
                booking.
              </>
            </p>
          </div>
        ),
      },
      {
        title: 'Do you provide lunch at an Active Kids holiday club?',
        content: (
          <div>
            <p>
              Yes we do. We will provide a healthy packed lunch for children
              attending the holiday clubs. We will also provide plenty of fruit
              and snacks throughout the day.
            </p>
            <p>
              Please note that we will make dietary accommodations where
              possible, however if your child has any allergies or individual
              requirements would prefer to bring their own lunch, please provide
              them with a packed lunch.
            </p>
            <p>Sample lunch menu:</p>
            <ul>
              <li>A sandwich (meat and vegetarian options)</li>
              <li>Carrot sticks</li>
              <li>A malt loaf bar</li>
              <li>A sugar free jelly pot</li>
            </ul>
            <p>Sample snack options:</p>
            <ul>
              <li>Fruit</li>
              <li>Cheese</li>
              <li>Yoghurt</li>
            </ul>
            <p>
              It would be helpful if you could provide your child with a
              reusable water bottle if you have one.
            </p>
          </div>
        ),
      },
      {
        title: 'Why aren’t there any clubs in my local area?',
        content: (
          <div>
            <p>
              We have expanded to 150 locations this year and we hope our clubs
              will continue to grow. Please keep a look out for an Active Kids
              club in a location near you next year.
            </p>
          </div>
        ),
      },
      {
        title: 'What time does the holiday club start and end?',
        content: (
          <div>
            <p>
              Registration opens at 8:45am and the club ends at 4pm.
              Unfortunately, we can’t look after children outside these times.
            </p>
            <p>
              Active Kids 2020 will run Mondays – Fridays, 27th July to 28th
              August.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionTitle: 'My Booking',
    children: [
      {
        title: 'How do I book my child/ children on to the clubs?',
        content: (
          <p>
            <>
              You can book your child/ children in to a holiday club by clicking
              on this link
            </>
            <> </>
            <Link to={routes.venues}>link</Link>
            <>
              . Booking is simple, just choose a club near you, enter some
              details about yourself and your child, and then pay by card or
              Nectar points.
            </>
          </p>
        ),
      },
      {
        title: 'Is there a maximum number of days I can book for each child?',
        content: (
          <>
            <p>
              You can book a child on to as many days as you want, availability
              permitting. We encourage children to take part for a full week to
              ensure they experience all the great activities on offer.
            </p>
            <ol>
              <li>
                The charge is: £10 per child for each Session booked.
                <li>
                  a. For example, if you book 2 children for two Sessions each,
                  you’ll pay £40 (4 Sessions at £10 each) and you may choose to
                  fully or partially pay for this using Nectar points.
                </li>
              </li>
              <li>
                There is no discount for multiple Sessions. Payment must be made
                by credit/debit card or Nectar points at time of booking. We’re
                sorry but we can’t accept any childcare vouchers and cash cannot
                be accepted. This offer is not available in conjunction with any
                other promotion.
              </li>
              <li>
                Once you’ve completed your booking, you will receive an email
                confirming the details and booking. Please check it carefully.
                This email will be sent to the email address provided by you on
                your booking form.
              </li>
            </ol>
          </>
        ),
      },
      {
        title: 'How many children can I book places for?',
        content: (
          <p>
            You can book a place for up to five children and they can attend as
            many days as you want. If you want to book more than five children
            you will need to make a separate booking.
          </p>
        ),
      },
      {
        title: 'Can I book a place on the clubs in a Sainsbury’s store?',
        content: (
          <>
            <p>
              <>
                Unfortunately, you are not able to book places in a Sainsbury’s
                store. Bookings should be made online via the Active Kids
                website,
              </>
              <> </>
              <Link to={routes.venues}> click here to make your booking.</Link>
            </p>

            <p>
              <>
                If the booking form is not accessible for you, please give us a
                call on
              </>
              <> </>
              <a href="tel:01953 499088">01953 499088 </a>
              <> </>
              or email
              <> </>
              <a href="mailto:activekids@premier-education.com">
                activekids@premier-education.com
              </a>
              <> and we can support you through the process.</>
            </p>
          </>
        ),
      },
      {
        title: 'How do I amend or cancel my booking?',
        content: (
          <div>
            <p>
              You can view your booking
              <a href="https://activekids.premier-education.com">here</a>
              <>
                . If you would like to cancel or amend your booking please call
                Premier Education on
              </>
              <> </>
              <a href="tel:01953499088">01953 499088</a>
              <>, Mon-Fri, 9am - 5pm</>
            </p>
            <p>
              <>
                Our team at Premier will try to accommodate changes and
                cancellations as best as possible, however we cannot guarantee
                anything if outside of the timelines above. However, please do
                give us a call and we will try to help as best we can. Please
                call Premier Education on
              </>
              <> </>
              <a href="tel:01953499088">01953 499088</a>
              <> or email them at </>
              <a href="mailto:activekids@premier-education.com">
                activekids@premier-education.com
              </a>
            </p>
            <p>We will accept cancellations on the following basis:</p>
            <ol type="a">
              <li>
                You may cancel and receive a refund up to 14 days before the day
                your booked Session is due to take place.
              </li>
              <li>
                <>
                  If you book less than 14 days before your Session is due to
                  take place you may cancel up to the day before the Session.
                  You accept and agree that the right to cancel is lost once the
                  contract is fully performed. To cancel a booking within these
                  timescales, please email Premier Education
                </>
                <> </>
                <a href="mailto:activekids@premier-education.com">
                  activekids@premier-education.com
                </a>
                <> or call on </>
                <a href="tel:01953499088">01953 499088</a>
                <>
                  . Any refund will be credited by Premier Education to the card
                  used at time of purchase. Premier Education will contact you
                  for card details for any cancellations paid with Nectar
                  points.
                </>
              </li>
              <p>
                <>
                  Our team at Premier will try to accommodate changes and
                  cancellations as best as possible, however we cannot guarantee
                  anything if outside of the timelines above. However, please do
                  give us a call and we will try to help as best we can. Please
                  call Premier Education on
                </>
                <> </>
                <a href="tel:01953499088">01953 499088</a>
                <> or email them at </>
                <a href="mailto:activekids@premier-education.com">
                  activekids@premier-education.com
                </a>
              </p>
            </ol>
          </div>
        ),
      },
      {
        title: 'What would happen if a club was cancelled?',
        content: (
          <div>
            <p>
              We reserve the right to cancel any or all Sessions for reasons
              outside of our reasonable control (a ‘Force Majeure’ event).
              Should such an event occur, Premier Education or the Promoter will
              contact customers as soon as possible or practicable and in any
              event no later than the day of the Session. Where Sessions are
              cancelled by us you will receive a full refund for those cancelled
              sessions.
            </p>
          </div>
        ),
      },
      {
        title:
          'How will Sainsbury’s and Premier Education use my personal contact details?',
        content: (
          <div>
            <p>
              <>Please see </>
              <a href="https://help.sainsburys.co.uk/help/website/privacy-policy-commitment">
                our privacy policy
              </a>
            </p>
          </div>
        ),
      },
      {
        title: 'How do I contact Premier Education?',
        content: (
          <div>
            <p>
              <>
                For any further questions, support with booking or to discuss
                your child’s specific needs call
              </>
              <> </>
              <a href="tel:01953499088">01953 499088</a>
              <> or email them at </>
              <a href="mailto:activekids@premier-education.com">
                activekids@premier-education.com
              </a>
            </p>
          </div>
        ),
      },
      {
        title:
          'Can I use childcare vouchers or tax free credits to pay for a place?',
        content: (
          <div>
            <p>
              No. Unfortunately we are unable to accept childcare vouchers or
              tax free vouchers as payment towards our Active Kids clubs.
              However, you can pay for places at a club using your Nectar points
              if you have enough saved up.
            </p>
          </div>
        ),
      },
    ],
  },
]

export default config.isSessionOpen ? FAQs2020 : FAQs2019
