import React from 'react'
import { Card, Heading, Section, Accordion, AccordionItem } from '@jsluna/react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import SEO from '../components/Seo'
import './index.scss'
import FAQs from '../data/faqs'

const FAQ = () => {
  return (
    <Layout testid="faq-page">
      <SEO title="FAQs" />
      <Section className="page">
        <Hero />
        <Section className="page_content">
          <Card className="faq__wrapper">
            <Heading level={3}>Frequently Asked Questions</Heading>
            {FAQs.map(({ sectionTitle, children }) => (
              <Section key={sectionTitle}>
                <Heading level={4}>{sectionTitle}</Heading>
                <Accordion multipleOpen>
                  {children.map(({ title, content }) => (
                    <AccordionItem title={title} key={title}>
                      {content}
                    </AccordionItem>
                  ))}
                </Accordion>
              </Section>
            ))}
          </Card>
        </Section>
      </Section>
    </Layout>
  )
}
export default FAQ
